// @flow

import React from 'react';
import { observer, inject } from 'mobx-react';
import { graphql } from 'gatsby';
import { Provider } from 'mobx-react';

import {STORE_JSON, STORE_UI} from '../constants/stores';
import JsonStore from '../stores/json-store';
import UiStore from '../stores/ui-store';
import Metadata from '../components/Metadata';
import styles from "./page.module.scss";
import includes from "lodash/includes";
import PhotoBlurbBlock from "../components/blocks/PhotoBlurbBlock";
import CTABlock from "../components/blocks/CTABlock";
import TextBlock from "../components/blocks/TextBlock";
import IFrameBlock from "../components/blocks/IFrameBlock";


type IndexProps = {
  json: JsonStore,
  ui: UiStore,
  data: any,
  location: any,
}

@inject(STORE_JSON, STORE_UI)
@observer
class IndexPage extends React.Component<IndexProps, any> {

  stores: any;

  constructor(props: IndexProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, this.props.data.update.url);
  }

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === 'true' && window) {
      this.props[STORE_JSON].getData('update', {
        contentType: 'update',
        fieldEquality: {
          url: this.props.data.update.url
        }
      }, false, this.props.data.update.url);
    }
  }

  render() {
    const jsonData: any = this.props[STORE_JSON].store.get(this.props.data.update.url);
    const realData = jsonData || this.props.data;

    return (
      <Provider {...this.stores}>
        <>
          <Metadata data={realData.update.metadata || {
            data: {}
          }}
                    location={this.props.location}/>
          <div className={styles.container}>
            <div className={styles.content}>
              {realData.update.blocks && <div className={styles.blocks}>
                {realData.update.blocks.map((item, index) => {
                  return (
                    <React.Fragment
                      key={`${item.contentType}${index}${realData.update.url}`}>
                      {includes(['ContentfulPhotoBlurbBlock', 'photoBlurbBlock'], item.contentType) &&
                      <PhotoBlurbBlock data={item}
                                       ui={this.props.ui}/>}
                      {includes(['ContentfulCtaBlock', 'ctaBlock'], item.contentType) &&
                      <CTABlock data={item}/>}
                      {includes(['ContentfulTextBlock', 'textBlock'], item.contentType) &&
                      <TextBlock data={item}/>}
                      {includes(['ContentfulIFrameBlock', 'iFrameBlock'], item.contentType) &&
                      <IFrameBlock data={item}/>}
                    </React.Fragment>
                  )
                })}
              </div>}
            </div>
          </div>
        </>
      </Provider>
    )
  }
}

export const query = graphql`
query($url: String!) {
  update: contentfulUpdateEntry(url:{eq:$url}){
      blocks {
        contentType: __typename
        ...on ContentfulGalleryBlock {
            header {
              markdown: header
            }
            fragmentPath
            dividerBelow
            images {
              large: src {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
              small: srcSmall {
                file {
                  url
                  details {
                    image {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        ...on ContentfulCtaBlock {
          dividerBelow
          fragmentPath
          title
          buttons {
            type
            display
            url
            externalUrl
          }
        }
        ...on ContentfulIFrameBlock {
            height
            dividerBelow
            url
            ratioPreset
            heightWidthRatio
            fragmentPath
            header {
              markdown: header
            }
        }
        ...on ContentfulTextBlock {
          noVerticalDivider
          dividerBelow
          fragmentPath
          header {
            markdown: header
          }
          content {
            markdown: content
          }
          secondColumn {
            markdown: secondColumn
          }
          thirdColumn {
            markdown: thirdColumn
          }
          listBulletStyle
        }
        ...on ContentfulPhotoBlurbBlock {
          dividerBelowTextBlock
          dividerBelow
          schedule {
            friday {
              display
              time
            }
            saturday {
              display
              time
            }
            sunday {
              display
              time
            }
          }
          header {
            markdown: header
          }
          framedImage {
            type
            src {
              file {
                url
              }
            }
            srcSmall {
              file {
                url
              }
            }
          }
          secondFramedImage {
            type
            src {
              file {
                url
              }
            }
            srcSmall {
              file {
                url
              }
            }
          }
          fragmentPath
          blurb {
            markdown: blurb
          }
        }
      }
      metadata {
        title
        description
        image {
          file {
            url
          }
        }
        ogTitle
        ogDescription
        twitterCard
      }
      name
      url
  }
}`;

export default IndexPage;
